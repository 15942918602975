import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ToastBox.module.scss';
import { getToastMessages } from '../../../DataCore/Store/Session/slice';
import { TOAST_STATUS } from '../../const';
import ToastService from '../../../DataCore/Service/ToastService';

const maxViewToasts = process.env.REACT_APP_MAX_VIEW_TOASTS || 3;
const toastTimeoutSec = process.env.REACT_APP_TOAST_TIMEOUT_SEC || 5;

function ToastItem({ toast, onClose }) {
  const { t } = useTranslation();
  useEffect(() => {
    const timeoutId = toast && onClose
      ? setTimeout(() => onClose(toast.uuid), toastTimeoutSec * 1000)
      : undefined;

    return () => clearTimeout(timeoutId);
  }, [onClose, toast]);

  return (
    <div
      key={toast.uuid}
      className={classNames(
        styles.toast,
        {
          [styles.primary]: toast.status === TOAST_STATUS.PRIMARY,
          [styles.success]: toast.status === TOAST_STATUS.SUCCESS,
          [styles.warning]: toast.status === TOAST_STATUS.WARNING,
          [styles.danger]: toast.status === TOAST_STATUS.DANGER,
        },
      )}
    >
      <span>
        {toast.message || t(toast.messageKey)}
      </span>
    </div>
  );
}

ToastItem.propTypes = {
  toast: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ToastBox() {
  const toasts = useSelector(getToastMessages);

  return (
    <div
      className={styles.box}
    >
      {toasts.slice(0, maxViewToasts).map((toast) => (
        <ToastItem
          key={toast.uuid}
          toast={toast}
          onClose={ToastService.deleteToast}
        />
      ))}
    </div>
  );
}

export default ToastBox;
