import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useDrop } from 'react-dnd';
import _some from 'lodash/some';
import _findIndex from 'lodash/findIndex';
import styles from './AchievesPage.module.scss';
import {
  getLatestAchievements,
  getSpacesWithAchievements,
  getTopAchievements,
} from '../../DataCore/Store/Achievement/slice';
import Star from '../../Common/Images/Star.svg';
import Button from '../../Common/Components/Button/Button';
import CloseFullscreen from '../../Common/Images/Collapse.svg';
import SpaceAchieveBox from '../../Common/Components/SpaceAchieveBox/SpaceAchieveBox';
import {
  DRAG_TYPES, GOAL_DROP, SPACE_COLORS, SPACE_ICONS,
} from '../../Common/const';
import AchieveBoxDrag from '../../Common/Components/AchieveBoxDrag/AchieveBoxDrag';
import AchievementService from '../../DataCore/Service/AchievementService';
import TopAchieveBox from '../../Common/Components/TopAchieveBox/TopAchieveBox';
import SortableSpaces from '../../Common/Components/SortableSpaces/SortableSpaces';
import GoalFull from '../../DataCore/Models/GoalFull';
import CustomScroll from '../../Common/Components/CustomScroll/CustomScroll';

function AchievesDrop({ space, render, onReorder }) {
  const [, dropGoal] = useDrop({
    accept: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    drop: (item) => {
      const findIndex = _findIndex(space.goals, ['id', item.goal.id]);
      return ({
        name: GOAL_DROP.ACHIEVE,
        goal: new GoalFull({
          ...item.goal,
          spaceId: space.id,
          index: findIndex >= 0 ? findIndex : space.goals.length,
        }),
      });
    },
    hover(item, monitor) {
      const hoverOnGoal = !monitor.isOver({ shallow: true });
      if (hoverOnGoal) {
        return;
      }

      if (_some(space.goals, ['id', item.goal.id])) {
        return;
      }

      const hoverIndex = space.goals.length;
      const hoverSpaceId = space.id;
      const dragStartSpaceId = item.startSpaceId;
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new GoalFull({
          ...item.goal,
          index: hoverIndex,
          spaceId: hoverSpaceId,
          space,
        }),
      });
    },
  });

  return (
    render({ innerRef: dropGoal })
  );
}

function AchievesPage() {
  const achievements = useSelector(getLatestAchievements);
  const topAchievements = useSelector(getTopAchievements);
  const spacesWithAchievements = useSelector(getSpacesWithAchievements);
  const [selectAllAchieve, setSelectAllAchieve] = useState(true);
  const { t } = useTranslation('translation', { keyPrefix: 'achievesPage' });

  const dropAccept = useMemo(() => [DRAG_TYPES.SPACE, DRAG_TYPES.GOAL_FROM_ACHIEVE], []);

  const handleReorderAchieve = ({ goal: reorderAchievement } = {}) => {
    AchievementService.setReorderAchievement(reorderAchievement);
  };

  return (
    <div className={styles.box}>
      <CustomScroll scrollLiftOnWheel={false} dropAccept={dropAccept} className={styles.content}>
        <div className={styles.header}>
          <div className={styles.closeButton} />
          <div className={styles.title}>
            <img className={styles.titleIcon} src={Star} alt="" />
            <h3 className={styles.titleText}>{t('achievements')}</h3>
          </div>
          <Link to="/">
            <Button
              icon={CloseFullscreen}
              className={styles.closeButton}
            />
          </Link>
        </div>
        <div>
          <div className={styles.topAchievementsBox}>
            <TopAchieveBox number={1} onReorder={handleReorderAchieve} goal={topAchievements[1]} />
            <TopAchieveBox number={2} onReorder={handleReorderAchieve} goal={topAchievements[2]} />
            <TopAchieveBox number={3} onReorder={handleReorderAchieve} goal={topAchievements[3]} />
            <TopAchieveBox number={4} onReorder={handleReorderAchieve} goal={topAchievements[4]} />
            <TopAchieveBox number={5} onReorder={handleReorderAchieve} goal={topAchievements[5]} />
          </div>
          <div className={styles.buttonBox}>
            <div className={styles.buttonGroup}>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                className={classNames(styles.radioButton, { [styles.selected]: selectAllAchieve })}
                type="button"
                onClick={() => setSelectAllAchieve(true)}
              >
                {t('allAchievements')}
              </button>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                className={classNames(styles.radioButton, { [styles.selected]: !selectAllAchieve })}
                type="button"
                onClick={() => setSelectAllAchieve(false)}
              >
                {t('bySpaces')}
              </button>
            </div>
          </div>
          {selectAllAchieve ? (
            <SpaceAchieveBox spaceName={t('allAchievements')} color={SPACE_COLORS.GREEN} spaceIcon={SPACE_ICONS.PERSON}>
              <div className={styles.achievements}>
                {achievements.map((goal) => (
                  <AchieveBoxDrag
                    key={goal.id}
                    goal={goal}
                  />
                ))}
              </div>
            </SpaceAchieveBox>
          ) : (
            <div className={styles.spaceAchieves}>
              <SortableSpaces
                spaces={spacesWithAchievements}
                renderSpace={({ innerRef, space, isDragging }) => (
                  <SpaceAchieveBox
                    innerRef={innerRef}
                    className={styles.spaceBox}
                    color={SPACE_COLORS[space.color]}
                    spaceName={space.name}
                    spaceIcon={space.icon}
                    transparency={isDragging}
                  >
                    <AchievesDrop
                      space={space}
                      onReorder={handleReorderAchieve}
                      render={({ innerRef: ref }) => (
                        <div ref={ref} className={styles.achievements}>
                          {space.goals.map((goal, i) => (
                            <AchieveBoxDrag
                              key={goal.id}
                              goal={goal}
                              index={i}
                              onReorder={handleReorderAchieve}
                            />
                          ))}
                        </div>
                      )}
                    />
                  </SpaceAchieveBox>
                )}
              />
            </div>
          )}
        </div>
      </CustomScroll>
    </div>
  );
}

export default AchievesPage;
