import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { EMOJI } from '../../const';
import styles from './GoalIcon.module.scss';

const { REACT_APP_EMOJI_URL } = process.env;

function GoalIcon({
  iconName, size, selectedIcon, className, ...props
}) {
  const src = `${REACT_APP_EMOJI_URL}/${size}/${iconName}.png`;

  const imgClass = useMemo(
    () => classNames(styles.icon, { [styles.select]: selectedIcon === iconName }, className),
    [iconName, selectedIcon, className],
  );

  return (
    <img className={imgClass} src={src} alt="" {...props} />
  );
}

GoalIcon.propTypes = {
  iconName: PropTypes.oneOf(Object.values(EMOJI).map(({ name }) => name)).isRequired,
  size: PropTypes.oneOf([20, 26, 32, 40, 48, 52, 64, 96, 160]),
  selectedIcon: PropTypes.oneOf(Object.values(EMOJI).map(({ name }) => name)),
  className: PropTypes.string,
};

GoalIcon.defaultProps = {
  size: 40,
  selectedIcon: undefined,
  className: '',
};

export default GoalIcon;
