import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _random from 'lodash/random';
import classNames from 'classnames';
import styles from './GoalBoxPlaceholder.module.scss';
import { ReactComponent as CartSvg } from './Cart.svg';
import { ReactComponent as DollarSvg } from './Dollar.svg';
import { ReactComponent as LikeSvg } from './Like.svg';
import { ReactComponent as MapSvg } from './Map.svg';
import { ReactComponent as StarSvg } from './Star.svg';

function GoalBoxPlaceholder({ achievement }) {
  const Icon = useMemo(() => {
    switch (_random(4)) {
      case 0:
        return CartSvg;
      case 1:
        return DollarSvg;
      case 2:
        return LikeSvg;
      case 3:
        return MapSvg;
      default:
        return StarSvg;
    }
  }, []);

  return (
    <div className={classNames(styles.box, { [styles.achievement]: achievement })}>
      <Icon className={styles.icon} />
      <div className={styles.placeholder_1} />
      <div className={styles.placeholder_2} />
      <div className={styles.placeholder_3} />
      <div
        className={styles.checkbox}
      />
    </div>
  );
}

GoalBoxPlaceholder.propTypes = {
  achievement: PropTypes.bool,
};

GoalBoxPlaceholder.defaultProps = {
  achievement: false,
};

export default GoalBoxPlaceholder;
