/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import _random from 'lodash/random';
import Star from '../../Images/Star.svg';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import { getLatestAchievements, getLoadingAchievements } from '../../../DataCore/Store/Achievement/slice';
import styles from './AchievesList.module.scss';
import OpenFullscreen from '../../Images/Expand.svg';
import Button from '../Button/Button';
import CustomScroll from '../CustomScroll/CustomScroll';
import AchieveBox from '../AchieveBox/AchieveBox';
import GoalBoxPlaceholder from '../GoalBoxPlaceholder/GoalBoxPlaceholder';

function AchievesList() {
  const latestAchievements = useSelector(getLatestAchievements);
  const loadingAchievements = useSelector(getLoadingAchievements);
  const { t } = useTranslation('translation', { keyPrefix: 'achievesList' });
  const achievementCount = useMemo(() => _random(1, 5), []);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: DRAG_TYPES.GOAL_FROM_SPACE,
    drop: () => ({ name: GOAL_DROP.ACHIEVE }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), []);

  return (
    <div
      ref={drop}
      className={classNames(
        styles.achievesBox,
        {
          [styles.canDrag]: canDrop && !isOver,
          [styles.canDropOver]: canDrop && isOver,
        },
      )}
    >
      <div className={styles.header}>
        <div className={styles.title}>
          <img className={styles.titleIcon} src={Star} alt="" />
          <h3 className={styles.titleText}>{t('latestAchievements')}</h3>
        </div>
        <Link to="/Achievements">
          <Button
            icon={OpenFullscreen}
            className={styles.button}
          />
        </Link>
      </div>
      <CustomScroll
        className={styles.achievementBox}
        scrollLiftOnWheel
      >
        <div className={styles.achievements}>
          {loadingAchievements && !latestAchievements?.length
            ? [...Array(achievementCount)].map((item, index) => (
              <GoalBoxPlaceholder key={index} achievement />
            )) : latestAchievements.map((goal) => (
              <AchieveBox
                key={goal.id}
                goal={goal}
              />
            ))}
        </div>
      </CustomScroll>
    </div>
  );
}

export default AchievesList;
