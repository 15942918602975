import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { VKShareButton, TelegramShareButton, FacebookShareButton } from 'react-share';
import classNames from 'classnames';
import moment from 'moment';
import styles from './ShareAchieveDialog.module.scss';
import GoalIcon from '../GoalIcon/GoalIcon';
import BaseDialog from '../BaseDialog/BaseDialog';
import Telegram from '../../Images/Telegram.svg';
import Facebook from '../../Images/Facebook.svg';
import Vk from '../../Images/Vk.svg';
import { DATE_FORMAT, YM_ACTION_TYPES } from '../../const';
import { ymAction } from '../../func';

const newAchievementDays = process.env.REACT_APP_NEW_ACHIEVEMENT_DAYS;

function ShareAchieveDialog({
  goal, showDialog, onClose,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'shareAchieveDialog' });

  const isNewAchieve = useMemo(() => {
    if (!goal || !goal.completedDate) return false;

    const date = moment(goal.completedDate, DATE_FORMAT);
    return date.isValid() && moment().isBefore(date.add(newAchievementDays, 'days'));
  }, [goal]);

  return goal ? (
    <BaseDialog className={styles.dialog} showDialog={showDialog} onClose={onClose} liteClose>
      <GoalIcon className={styles.icon} iconName={goal.icon} size={160} />
      <div className={styles.name}>
        {isNewAchieve ? t('newAchieve') : t('myAchieve')}
      </div>
      <h2 className={styles.description}>{goal.name}</h2>
      {goal.shareLink ? (
        <>
          <div className={styles.share}>{t('share')}</div>
          <div className={styles.shareButtons}>
            <TelegramShareButton
              title={goal.name}
              url={goal.shareLink}
              className={classNames(styles.button, styles.telegramButton)}
              onClick={() => {
                ymAction(YM_ACTION_TYPES.SHARE_ACHIEVEMENT_TELEGRAM);
              }}
            >
              <img className={styles.buttonIcon} src={Telegram} alt="Telegram" />
            </TelegramShareButton>
            <FacebookShareButton
              quote={goal.name}
              url={goal.shareLink}
              className={classNames(styles.button, styles.facebookButton)}
              onClick={() => {
                ymAction(YM_ACTION_TYPES.SHARE_ACHIEVEMENT_FACEBOOK);
              }}
            >
              <img className={styles.buttonIcon} src={Facebook} alt="Facebook" />
            </FacebookShareButton>
            <VKShareButton
              title={goal.name}
              url={goal.shareLink}
              className={classNames(styles.button, styles.vkButton)}
              onClick={() => {
                ymAction(YM_ACTION_TYPES.SHARE_ACHIEVEMENT_VK);
              }}
            >
              <img className={styles.buttonIcon} src={Vk} alt="Vk" />
            </VKShareButton>
          </div>
        </>
      ) : null}
    </BaseDialog>
  ) : null;
}

ShareAchieveDialog.propTypes = {
  goal: PropTypes.object,
  showDialog: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShareAchieveDialog.defaultProps = {
  goal: undefined,
};

export default ShareAchieveDialog;
