import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _find from 'lodash/find';
import { endLoadingSpaces, setReorderGoal, startLoadingSpaces } from '../Store/Space/slice';
import GoalRepository from '../Repositories/Api/GoalRepository';
import SpaceService from './SpaceService';
import store from '../Store/store';
import { ymAction } from '../../Common/func';
import { GOAL_STATES, YM_ACTION_TYPES } from '../../Common/const';

export default class GoalService {
  static async create(spaceId, goal) {
    store.dispatch(startLoadingSpaces());

    const resultCreate = await GoalRepository.create(spaceId, goal);
    if (!resultCreate.status) {
      store.dispatch(endLoadingSpaces());
      return resultCreate;
    }

    ymAction(YM_ACTION_TYPES.CREATE_GOAL);
    return SpaceService.getAll();
  }

  static async update(spaceId, goal) {
    store.dispatch(startLoadingSpaces());

    const resultCreate = await GoalRepository.update(spaceId, goal);
    if (!resultCreate.status) {
      store.dispatch(endLoadingSpaces());
      return resultCreate;
    }

    const state = store.getState();
    const { spaces } = state.space;
    const goals = _flatten(_map(spaces, 'goals'));
    const { achievements } = state.achievement;
    const abandonedGoals = state.blackHole.goals;
    const oldGoal = _find([...goals, ...achievements, ...abandonedGoals], ['id', goal.id]);

    if (oldGoal) {
      if (oldGoal.spaceId === goal.spaceId && oldGoal.name !== goal.name) {
        ymAction(YM_ACTION_TYPES.RENAME_GOAL);
      } else if (oldGoal.spaceId !== goal.spaceId && oldGoal.state === GOAL_STATES.ACHIEVED) {
        ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_ANOTHER_SPACE);
      } else if (oldGoal.spaceId !== goal.spaceId && oldGoal.state === GOAL_STATES.IN_PROGRESS) {
        ymAction(YM_ACTION_TYPES.MOVING_GOAL_TO_ANOTHER_SPACE);
      } else if (oldGoal.state === GOAL_STATES.IN_PROGRESS && goal.state === GOAL_STATES.ACHIEVED) {
        ymAction(YM_ACTION_TYPES.REACHED_GOAL);
        // eslint-disable-next-line max-len
      } else if (oldGoal.state === GOAL_STATES.IN_PROGRESS && goal.state === GOAL_STATES.IN_ARCHIVE) {
        ymAction(YM_ACTION_TYPES.ABANDONED_GOAL);
        // eslint-disable-next-line max-len
      } else if (oldGoal.state === GOAL_STATES.IN_ARCHIVE && goal.state === GOAL_STATES.IN_PROGRESS) {
        ymAction(YM_ACTION_TYPES.RESURRECTED_GOAL);
      } else if (!oldGoal.favoriteIndex && goal.favoriteIndex) {
        ymAction(YM_ACTION_TYPES.MOVING_ACHIEVEMENT_TO_TOP);
      }
    }

    ymAction(YM_ACTION_TYPES.EDIT_GOAL);
    return SpaceService.getAll();
  }

  static setReorderGoal(goal) {
    store.dispatch(setReorderGoal(goal));
  }
}
