import {
  endLoadingAchievements,
  setAchievements,
  setReorderAchievements,
  startLoadingAchievements,
} from '../Store/Achievement/slice';
import AchievementRepository from '../Repositories/Api/AchievementRepository';
import { DIALOG_NAMES } from '../../Common/const';
import { openDialog } from '../Store/Dialog/slice';
import store from '../Store/store';

export default class AchievementService {
  static async getAll() {
    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getAll();
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    store.dispatch(setAchievements(result.data));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static async loadAndOpenGoalDialog(goalId) {
    store.dispatch(startLoadingAchievements());

    const result = await AchievementRepository.getById(goalId);
    if (!result.status) {
      store.dispatch(endLoadingAchievements());
      return result;
    }

    const name = DIALOG_NAMES.ACHIEVE_SHARE;
    const goal = result.data;
    store.dispatch(openDialog({ name, goal }));
    store.dispatch(endLoadingAchievements());

    return { status: true };
  }

  static setReorderAchievement(achievement) {
    store.dispatch(setReorderAchievements(achievement));
  }
}
