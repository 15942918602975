/* eslint-disable */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './SignIn.module.scss';
import Logo from '../../Common/Images/Logo.svg';
import Slide1 from './Slide1.jpg';
import Telegram from '../../Common/Images/Telegram.svg';
import Google from '../../Common/Images/Google.svg';
import Facebook from '../../Common/Images/Facebook.svg';
import { LOGIN_PROVIDER_TYPES, TOAST_STATUS, YM_ACTION_TYPES } from '../../Common/const';
import { ymAction } from '../../Common/func';
import PrivacyPolicyLink from '../../Common/Components/PrivacyPolicyLink/PrivacyPolicyLink';
import AuthService from '../../DataCore/Service/AuthService';
import ErrorMessage from '../../Common/Components/ErrorMessage/ErrorMessage';
import ToastService from '../../DataCore/Service/ToastService';
import Toast from '../../DataCore/Models/Toast';

function redirect(providerName) {
  const redirectUrl = new URL('/SignIn', window.location);
  const url = new URL('/api/Auths', process.env.REACT_APP_BACKEND_URL);
  url.searchParams.set('authProvider', providerName);
  url.searchParams.set('returnUrl', redirectUrl.toString());
  window.location = url;
}

function SignInPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('Authorization');
  const errorMessage = searchParams.get('ErrorMessage');
  const { t } = useTranslation('translation', { keyPrefix: 'signInPage' });

  useEffect(() => {
    if (errorMessage) {
      ToastService.enqueueToast(new Toast({
        message: errorMessage,
        status: TOAST_STATUS.DANGER,
      }));
    }
  }, [errorMessage]);

  if (token) {
    AuthService.logIn(token);
  }

  const handleTelegram = () => {
    ymAction(YM_ACTION_TYPES.SIGN_IN_TELEGRAM);
    redirect(LOGIN_PROVIDER_TYPES.TELEGRAM);
  };

  const handleGoogle = () => {
    ymAction(YM_ACTION_TYPES.SIGN_IN_GOOGLE);
    redirect(LOGIN_PROVIDER_TYPES.GOOGLE);
  };

  const handleFacebook = () => {
    ymAction(YM_ACTION_TYPES.SIGN_IN_FACEBOOK);
    redirect(LOGIN_PROVIDER_TYPES.FACEBOOK);
  };

  return (
    <div className={styles.content}>
      <div className={styles.signInBox}>
        <div className={styles.info}>
          <img className={styles.logo} src={Logo} alt="" />
          <div className={styles.slider}>
            <img src={Slide1} alt="" />
            <p>{t('slade1')}</p>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.social}>
          <p>
            {t('socialSignInTitle')}
            :
          </p>
          <div className={styles.buttons}>
            <button type="button" onClick={handleTelegram} className={`${styles.button} ${styles.telegramButton}`}>
              <img className={styles.buttonIcon} src={Telegram} alt="Telegram" />
            </button>
            <button type="button" onClick={handleGoogle} className={`${styles.button} ${styles.googleButton}`}>
              <img className={styles.buttonIcon} src={Google} alt="Google" />
            </button>
            <button type="button" onClick={handleFacebook} className={`${styles.button} ${styles.facebookButton}`}>
              <img className={styles.buttonIcon} src={Facebook} alt="Facebook" />
            </button>
          </div>
        </div>
        <ErrorMessage content={errorMessage} className={styles.errorMessage} />
        <PrivacyPolicyLink />
      </div>
    </div>
  );
}

export default SignInPage;
