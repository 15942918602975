import React from 'react';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import styles from './TopAchieveBox.module.scss';
import AchieveBox from '../AchieveBox/AchieveBox';
import { DRAG_TYPES, GOAL_DROP } from '../../const';
import GoalFull from '../../../DataCore/Models/GoalFull';

function TopAchieveBox({ number, goal, onReorder }) {
  const [, drop] = useDrop({
    accept: DRAG_TYPES.GOAL_FROM_ACHIEVE,
    drop: (item) => ({
      name: GOAL_DROP.TOP_ACHIEVE,
      goal: new GoalFull({
        ...item.goal,
        favoriteIndex: number,
      }),
    }),
    hover(item) {
      const dragStartSpaceId = item.startSpaceId;
      onReorder({
        sourceSpaceId: dragStartSpaceId,
        goal: new GoalFull({
          ...item.goal,
          favoriteIndex: number,
        }),
      });
    },
  });

  return (
    <div ref={drop} className={styles.box}>
      <h2 className={styles.medal}>{number}</h2>
      {goal ? (
        <AchieveBox className={styles.achieve} goal={goal} />
      ) : (
        <div className={styles.empty} />
      )}
    </div>
  );
}

TopAchieveBox.propTypes = {
  number: PropTypes.number.isRequired,
  goal: PropTypes.object,
  onReorder: PropTypes.func.isRequired,
};

TopAchieveBox.defaultProps = {
  goal: undefined,
};

export default TopAchieveBox;
