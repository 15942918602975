import { ERROR_TYPES } from '../../../Common/const';

export default function parseResponse(
  response,
  successCallback,
  errorCallback,
) {
  switch (response && response.status) {
    case 200:
      return {
        status: true,
        data: successCallback && successCallback(response.data, response),
      };
    case 201:
    case 202:
    case 204:
      return {
        status: true,
      };
    case 400:
      return {
        status: false,
        errorType: ERROR_TYPES.BAD_REQUEST,
        error: errorCallback && errorCallback(response?.data?.errors, response),
      };
    case 401:
      return {
        status: false,
        errorType: ERROR_TYPES.UNAUTHORIZED,
      };
    case 403:
      return {
        status: false,
        errorType: ERROR_TYPES.FORBIDDEN,
      };
    case 404:
      return {
        status: false,
        errorType: ERROR_TYPES.NOT_FOUND,
      };
    case 500:
    case 503:
    case 504:
      return {
        status: false,
        errorType: ERROR_TYPES.SERVER_ERROR,
      };
    default:
      return {
        status: false,
        errorType: ERROR_TYPES.OTHER,
      };
  }
}
