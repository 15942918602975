import _invoke from 'lodash/invoke';
import HttpClient from './HttpClient';
import parseResponse from './parseResponse';
import GoalFull from '../../Models/GoalFull';

export default class AchievementRepository {
  static async getAll() {
    let body;
    const achievements = [];
    let index = 0;

    do {
      // eslint-disable-next-line no-await-in-loop
      const result = await HttpClient.get('achievements', {
        params: { index },
      }).then(
        (response) => parseResponse(response, (data) => data),
        ({ response }) => parseResponse(response),
      );

      if (!result.status) {
        return result;
      }

      body = result.data;
      const page = _invoke(body, 'data.map', GoalFull.fromApi) ?? [];
      achievements.push(...page);
      index += 1;
    } while (!body.isLastPage);

    return {
      status: true,
      data: achievements,
    };
  }

  static async getById(id) {
    return HttpClient.get(`achievements/${id}`).then(
      (response) => parseResponse(response, GoalFull.fromApi),
      ({ response }) => parseResponse(response),
    );
  }
}
