import store from '../Store/store';
import { deleteToastMessage, enqueueToastMessage } from '../Store/Session/slice';
import Toast from '../Models/Toast';

export default class ToastService {
  static enqueueToast(toast) {
    if (toast instanceof Toast) {
      store.dispatch(enqueueToastMessage(toast));
    } else {
      throw new Error('toast no instanceof Toast');
    }
  }

  static deleteToast(uuid) {
    store.dispatch(deleteToastMessage(uuid));
  }
}
