import { createSelector, createSlice } from '@reduxjs/toolkit';
import _sortBy from 'lodash/sortBy';
import _groupBy from 'lodash/groupBy';
import _values from 'lodash/values';
import _get from 'lodash/get';
import _differenceBy from 'lodash/differenceBy';
import _reduce from 'lodash/reduce';
import _mapValues from 'lodash/mapValues';
import initialState from './initialState';
import Space from '../../Models/Space';

const achievementSlice = createSlice({
  name: 'achievement',
  initialState,
  reducers: {
    startLoadingAchievements: (state) => ({
      ...state,
      loadingAchievements: true,
    }),
    endLoadingAchievements: (state) => ({
      ...state,
      loadingAchievements: false,
    }),
    setAchievements: (state, action) => {
      const achievements = action.payload;
      return {
        ...state,
        achievements,
      };
    },
    setReorderAchievements: (state, action) => ({
      ...state,
      reorderAchievement: action.payload,
    }),
    resetAchievement: () => initialState,
  },
});

export const {
  startLoadingAchievements,
  endLoadingAchievements,
  setAchievements,
  setReorderAchievements,
  resetAchievement,
} = achievementSlice.actions;

export const getLoadingAchievements = (state) => state.achievement.loadingAchievements;

export const getAchievements = (state) => state.achievement.achievements;

export const getLatestAchievements = createSelector(
  (state) => state.achievement.achievements,
  (achievements) => _sortBy(achievements, 'completedDate').reverse(),
);

export const getSpacesWithAchievements = createSelector(
  [
    (state) => state.achievement.achievements,
    (state) => state.achievement.reorderAchievement,
    (state) => state.space.spaces,
  ],
  (achievements, reorderAchievement, spaces) => {
    let groups = _mapValues(
      _groupBy(achievements, 'spaceId'),
      (group) => _sortBy(group, 'index'),
    );

    if (reorderAchievement) {
      if (!groups[reorderAchievement.spaceId]) {
        groups[reorderAchievement.spaceId] = [reorderAchievement];
      }

      groups = _mapValues(
        groups,
        (group) => {
          const spaceId = _get(group, '0.spaceId');
          const newGroup = _differenceBy(group, [reorderAchievement], 'id');
          if (reorderAchievement.spaceId === spaceId) {
            newGroup.splice(reorderAchievement.index, 0, reorderAchievement);
          }
          return newGroup;
        },
      );
    }

    if (spaces.length) {
      return _sortBy(spaces, 'index').map((space) => new Space({
        ...space,
        goals: _get(groups, space.id, []),
      }));
    }

    return _sortBy(_values(groups), '0.space.index').map((group) => new Space({
      ..._get(group, '0.space'),
      goals: group,
    }));
  },
);

export const getTopAchievements = createSelector(
  [
    (state) => state.achievement.achievements,
    (state) => state.achievement.reorderAchievement,
  ],
  (achievements, reorderAchievement) => {
    const topAchievements = _reduce(
      achievements,
      (top, achievement) => {
        if (achievement.favoriteIndex) {
          // eslint-disable-next-line no-param-reassign
          top[achievement.favoriteIndex] = achievement;
        }
        return top;
      },
      {},
    );

    if (reorderAchievement?.favoriteIndex) {
      topAchievements[reorderAchievement.favoriteIndex] = reorderAchievement;
    }

    return topAchievements;
  },
);

export const { reducer } = achievementSlice;
