import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { DRAG_TYPES, GOAL_DROP, GOAL_STATES } from '../../const';
import GoalBox from './GoalBox';
import GoalService from '../../../DataCore/Service/GoalService';
import Goal from '../../../DataCore/Models/Goal';
import BlackHoleService from '../../../DataCore/Service/BlackHoleService';

function GoalBoxDragBlackHole({
  goal,
}) {
  const [{ isDragging }, drag] = useDrag({
    type: DRAG_TYPES.GOAL_FROM_BLACK_HOLE,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    item: () => ({
      goal,
    }),
    end: async (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult?.name === GOAL_DROP.SPACE && dropResult.goal instanceof Goal) {
        const newGoal = new Goal({
          ...dropResult.goal,
          state: GOAL_STATES.IN_PROGRESS,
        });
        await GoalService.update(goal.spaceId, newGoal);
        await BlackHoleService.getAll();
      }
      GoalService.setReorderGoal();
    },
  });

  return (
    <GoalBox
      innerRef={drag}
      transparency={isDragging}
      goal={goal}
      showAchievementButton={false}
    />
  );
}

GoalBoxDragBlackHole.propTypes = {
  goal: PropTypes.object.isRequired,
};

export default GoalBoxDragBlackHole;
