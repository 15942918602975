import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _get from 'lodash/get';
import classNames from 'classnames';
import GoalIcon from '../GoalIcon/GoalIcon';
import {
  DATE_FORMAT, DIALOG_NAMES, SPACE_COLORS, VIEW_DATE_FORMAT,
} from '../../const';
import CheckGreen from './CheckGreen.svg';
import styles from './AchieveBox.module.scss';
import DialogService from '../../../DataCore/Service/DialogService';

function AchieveBox({
  innerRef, transparency, goal, className,
}) {
  const color = SPACE_COLORS[_get(goal, 'space.color')] || 'black';
  const completedDate = useMemo(() => {
    const date = moment(goal.completedDate, DATE_FORMAT);
    return date.isValid() ? date.format(VIEW_DATE_FORMAT) : '';
  }, [goal]);

  return (
    <button
      ref={innerRef}
      type="button"
      onClick={() => {
        DialogService.openDialog(DIALOG_NAMES.ACHIEVE_SHARE, { goal });
      }}
      className={classNames(
        styles.box,
        { [styles.transparency]: transparency },
        className,
      )}
      style={{ border: `2px solid ${color}` }}
    >
      <GoalIcon className={styles.icon} iconName={goal.icon} size={64} />
      <div className={styles.name}>{goal.name}</div>
      <div className={styles.deadline}>
        <img src={CheckGreen} alt="" />
        <div>{completedDate}</div>
      </div>
      <div className={styles.spaceName} style={{ color }}>
        {_get(goal, 'space.name')}
      </div>
    </button>
  );
}

AchieveBox.propTypes = {
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  transparency: PropTypes.bool,
  goal: PropTypes.object.isRequired,
  className: PropTypes.string,
};

AchieveBox.defaultProps = {
  innerRef: undefined,
  transparency: false,
  className: undefined,
};

export default AchieveBox;
