import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _toPairs from 'lodash/toPairs';
import { Field, Form as FinalForm } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { SPACE_COLORS, SPACE_ICONS } from '../../const';
import SpaceIcon from '../SpaceIcon/SpaceIcon';
import { composeValidators, maxLengthValidate, requiredValidate } from '../../validators';
import ActionButtons from '../ActionButtons/ActionButtons';
import styles from './SpaceFormDialog.module.scss';
import BaseDialog from '../BaseDialog/BaseDialog';

function SpaceFormDialog({
  showDialog, initialValues, title, confirmButtonText, onSubmit, onCancel,
}) {
  const { t } = useTranslation('translation', { keyPrefix: 'spaceForm' });

  const calculator = useMemo(() => createDecorator(
    {
      field: 'icon',
      updates: {
        name: (iconValue, allValues, prevValues) => {
          const calculateName = (icon) => {
            switch (icon) {
              case SPACE_ICONS.USER:
                return t('spaceIconUser');
              case SPACE_ICONS.NOTEBOOK:
                return t('spaceIconNotebook');
              case SPACE_ICONS.CAR:
                return t('spaceIconCar');
              case SPACE_ICONS.HOME:
                return t('spaceIconHome');
              case SPACE_ICONS.PERSON:
                return t('spaceIconPerson');
              case SPACE_ICONS.CALENDAR:
                return t('spaceIconCalendar');
              case SPACE_ICONS.AIRPLANE:
                return t('spaceIconAirplane');
              case SPACE_ICONS.BALL:
                return t('spaceIconBall');
              case SPACE_ICONS.TOOLS:
                return t('spaceIconTools');
              case SPACE_ICONS.DOLLAR:
                return t('spaceIconDollar');
              case SPACE_ICONS.IMAGE:
                return t('spaceIconImage');
              case SPACE_ICONS.MEDICATIONS:
                return t('spaceIconMedications');
              case SPACE_ICONS.TRUCK:
                return t('spaceIconTruck');
              case SPACE_ICONS.TEACHER:
                return t('spaceIconTeacher');
              case SPACE_ICONS.INFO:
                return t('spaceIconInfo');
              default:
                return t('spaceIconKey');
            }
          };

          if (!iconValue) {
            return allValues.name;
          }

          if (!allValues.name || allValues.name === calculateName(prevValues.icon)) {
            return calculateName(iconValue);
          }
          return allValues.name;
        },
      },
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      decorators={[calculator]}
      render={({
        handleSubmit, values, pristine, form: finalForm,
      }) => (
        <BaseDialog
          className={styles.dialog}
          showDialog={showDialog}
          onClose={onCancel}
          liteClose={pristine}
        >
          <form
            onSubmit={(event) => handleSubmit(event).then(() => finalForm.reset())}
            className={styles.spaceForm}
          >
            <h2 className={styles.header}>{title ?? t('title')}</h2>
            <div>
              <Field name="name" validate={composeValidators(requiredValidate, maxLengthValidate(40))}>
                {({ input, meta }) => (
                  <label
                    htmlFor="spaceName"
                    className={classNames(
                      styles.spaceNameLabel,
                      { [styles.error]: meta.touched && (meta.error || meta.submitError) },
                    )}
                  >
                    {t('name')}
                    <input
                      id="spaceName"
                      className={styles.spaceNameInput}
                      placeholder={t('namePlaceholder')}
                      maxLength={40}
                      {...input}
                    />
                    <div className={styles.errorMessage}>{meta.error ?? meta.submitError}</div>
                  </label>
                )}
              </Field>
            </div>
            <div>
              {t('icon')}
              <Field name="icon">
                {({ input }) => (
                  <div className={styles.icons}>
                    {_toPairs(SPACE_ICONS).map(([key, icon]) => (
                      <SpaceIcon
                        key={key}
                        className={styles.icon}
                        color={SPACE_COLORS[values.color]}
                        iconName={icon}
                        selectedIcon={input.value}
                        onClick={() => input.onChange(icon)}
                      />
                    ))}
                  </div>
                )}
              </Field>
            </div>
            <div>
              {t('color')}
              <Field name="color">
                {({ input }) => (
                  <div className={styles.colors}>
                    {_toPairs(SPACE_COLORS).map(([key, color]) => (
                      <button
                        key={key}
                        className={styles.colorBox}
                        style={input.value === key ? { border: `2px solid ${color}` } : {}}
                        type="button"
                        onClick={() => input.onChange(key)}
                      >
                        <div className={styles.color} style={{ background: color }} />
                      </button>
                    ))}
                  </div>
                )}
              </Field>
            </div>
            <ActionButtons
              confirmButtonText={confirmButtonText ?? t('create')}
              cancelButtonText={t('cancel')}
              onCancelButtonClick={() => {
                onCancel();
                finalForm.reset();
              }}
            />
          </form>
        </BaseDialog>
      )}
    />
  );
}

SpaceFormDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  confirmButtonText: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

SpaceFormDialog.defaultProps = {
  confirmButtonText: undefined,
  title: undefined,
};

export default SpaceFormDialog;
